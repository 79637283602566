<template>
<div class="tempLogin">
  <a-col :xs="{ span: 18 }" :md="{ span: 8 }" :xl="{ span: 6 }" class="form" >
    <a-form
      id="components-form-demo-normal-login"
      :form="form"
      class="login-form"
      @submit="handleSubmit"
    >
      <a-col span="24" class="loginTitle">翻红馆后台</a-col>
      <a-form-item>
        <a-input
          v-decorator="[
            'userName',
            {
              rules: [
                { required: true, message: '请输入用户名!' },
              ],
            },
          ]"
          placeholder="请输入用户名"
        >
          <a-icon
            slot="prefix"
            type="user"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
          v-decorator="[
            'password',
            {
              rules: [
                { required: true, message: '请输入密码!' },
              ],
            },
          ]"
          type="password"
          placeholder="请输入密码"
        >
          <a-icon
            slot="prefix"
            type="lock"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit" class="login-form-button">
          登录
        </a-button>
      </a-form-item>
    </a-form>
  </a-col>
  </div>
</template>

<script>
export default {
  data() {
    return {
      spinning:true,
      form :this.$form.createForm(this, { name: "normal_login" })
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  activated() {
    this.form.setFieldsValue({
      userName:'',
      password:'',
    })
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      var that = this;
      that.form.validateFields((err, values) => {
        if (!err) {
          that.$message.loading({ content: '加载中..',key: "load",duration: 0})
          var data = { username: values.userName, password: values.password };
          that.axios.get("/admin/login_users", { params: data }).then((res) => {
            that.$message.loading({ content: '加载中..', key: "load", duration: 0.00001 })
            if (that.$code(res)) {
              // that.axios.defaults.headers.post["Authorization"] =
              //   res.data.resultData;
              window.localStorage.setItem(
                "token",
                res.data.data.token
              );
              window.localStorage.setItem(
                "expiresAt",
                res.data.data.expiresAt
              );
              that.$message.loading({ content: '加载中..',key: "load",duration: 0})
              that.axios.get("/admin/me_users").then(function(mes) {
                that.$message.loading({ content: '加载中..', key: "load", duration: 0.00001 })
                if (that.$code(mes)) {
                  window.localStorage.setItem(
                    "username",
                    mes.data.data.username
                  );
                  window.localStorage.setItem(
                    "nickname",
                    mes.data.data.nickname
                  );
                  that.$nextTick(() => {
                    that.$router.push("/order/list");
                  })
                }
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.loginTitle{font-size: 24px;text-align: center;font-weight: 700;margin-bottom: 50px;}
.form {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
#components-form-demo-normal-login.login-form {
  width: 100%;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
.tempLogin{width: 100%;height:100%;display: flex;align-items: center;justify-content: center;}
</style>