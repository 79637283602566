var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tempLogin"},[_c('a-col',{staticClass:"form",attrs:{"xs":{ span: 18 },"md":{ span: 8 },"xl":{ span: 6 }}},[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-col',{staticClass:"loginTitle",attrs:{"span":"24"}},[_vm._v("翻红馆后台")]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'userName',
            {
              rules: [
                { required: true, message: '请输入用户名!' } ],
            } ]),expression:"[\r\n            'userName',\r\n            {\r\n              rules: [\r\n                { required: true, message: '请输入用户名!' },\r\n              ],\r\n            },\r\n          ]"}],attrs:{"placeholder":"请输入用户名"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                { required: true, message: '请输入密码!' } ],
            } ]),expression:"[\r\n            'password',\r\n            {\r\n              rules: [\r\n                { required: true, message: '请输入密码!' },\r\n              ],\r\n            },\r\n          ]"}],attrs:{"type":"password","placeholder":"请输入密码"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 登录 ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }